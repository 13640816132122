import logo from './logo-2.svg';
import './App.css';
import Send from "./Send";
import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#008158',
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
          <p>
            Цветы, букеты, композиции на заказ
          </p>
          <ThemeProvider theme={theme}>
            <Send/>
          </ThemeProvider>
        </header>
      </div>
  );
}

export default App;
