import {Component} from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Snackbar,
  TextField
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

class Send extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      name: '',
      email: '',
      phone: '',
      comment: '',
      response: false,
      error: false,
      loading: false
    };
  }

  handleClickOpen = () => {
    this.setState({showPopup: true});
  }

  handleClose = () => {
    this.setState({showPopup: false});
  }

  handleSubmit = evt => {
    this.setState({loading: true});
    evt.preventDefault();
    let data = this.state;
    console.log(data);
    fetch("/api/", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    })
        .then(response => response.json())
        .then(response => {
          this.setState({
            response: response.result,
            loading: false,
            showPopup: false
          })
        })
        .catch(error => this.setState({error: error.toString()}));
  }

  handleInput = evt => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    this.setState({[name]: newValue});
  };

  alert = () => {
    let open = false;
    if (this.state.response || this.state.error) {
      open = true;
    }
    let message = this.state.response ? this.state.response : this.state.error;
    console.log(this.state.response);
    console.log(this.state.error);
    return (
        <Snackbar open={open} autoHideDuration={6000}>
          <Alert severity={this.state.error ? "error" : "success"}>{message}</Alert>
        </Snackbar>
    );
  }

  render() {
    let open = this.state.showPopup;
    let loading = this.state.loading;
    return (
        <>
          <Button variant="outlined" onClick={this.handleClickOpen}>
            Заказать букет
          </Button>
          <Dialog open={open} onClose={this.handleClose}>
            <form onSubmit={this.handleSubmit}>
              <DialogTitle>Отправить заявку</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Заполните форму и с вами свяжется флорист для уточнений деталей заказа
                </DialogContentText>
                <TextField
                    required
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Ваше имя"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleInput}
                />
                <TextField
                    required
                    margin="dense"
                    id="email"
                    name="email"
                    label="Адрес email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleInput}
                />
                <TextField
                    required
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Номер телефона"
                    type="tel"
                    fullWidth
                    variant="outlined"
                    onChange={this.handleInput}
                />
                <TextField
                    required
                    margin="dense"
                    id="comment"
                    name="comment"
                    label="Комментарий к заказу"
                    type="tel"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    onChange={this.handleInput}
                />
              </DialogContent>
              <DialogActions>
                <LoadingButton
                    loading={loading}
                    className={"m"}
                    variant="contained"
                    color="primary"
                    type="submit">Отправить</LoadingButton>
              </DialogActions>
            </form>
          </Dialog>
          {this.alert()}
        </>
    );
  }
}

export default Send;